export const FREE = "FREE";
export const SCROLL_OFFSET = -130;
export const SECTION_OFFSET = 132;
export const SCROLL_DURATION = 500;
export const SECTION_OFFSET_ABOUT = 146;
export const PRIVACY_SCROLL_OFFSET = -170;
export const TEXT_AREA_MAX_LENGTH = "2000";
export const HUBSPOT_PORTAL_ID = "9197169";
export const LOCAL_API_URL = "http://localhost:8000";
export const LOCAL_REG_URL = "http://127.0.0.1:3000";
export const SPONSOR_FORM = "89d32b61-aed4-4eb1-9dd2-bfac90dc938d";
export const WAITLIST_FORM_ID = "e60fc803-bd9e-486c-a870-d42bff452fe9";
export const SPEAKER_FORM = "f98650c2-a3e0-4b2c-9934-1b61afcb9db9";
export const CO_LOCATED_EVENTS_FORM = "36e0d7ee-27b0-4c01-967e-ff5a5835b0c5";
export const CO_LOCATED_SPEAKERS_FORM = "2cdc9c5d-bb7a-477d-bccd-7e364e882ba8";
export const APPLY_TO_SPEAK_FORM = "ac4ac6a0-b3b2-4dcf-b38b-6c4b9adc6cec";
export const DEFAULT_FAVICON_URL = `${process.env.PUBLIC_URL}/favicon.ico`;
export const STAGING = "testing";
export const PRODUCTION = "fintech";
export const LOCALHOST = "localhost";
export const PROD = "production";
export const MIN_COUPON_CODE_LENGTH = 5;
export const MAX_COUPON_CODE_LENGTH = 50;
export const TRUE = "true";
export const HOSTED_TICKETS = ["retailerFree", "bankFree"];
export const PROD_URL = "https://fintechmeetup.com";
export const STAGING_URL = "https://testing.fintechmeetup.com";
export const LOCAL_COPIES_URL =
  "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-non-prod";
export const PROD_COPIES_URL =
  "https://us-central1-pti-fintech-meetup.cloudfunctions.net/fintech-website-prod";
export const FORM_ID = "fId";
export const COUPON_CODE = "cId";
export const SPONSORS_ORG_ID = "spId";
export const REGISTRATION_ROUTE_INITIATE_REG = "/form/initiate";
export const MARQUEE_COUNT = 10;
export const MAX_LENGTH_140 = 140;
export const MAX_LENGTH_500 = 500;
