import axios from "axios";
import { getEnvironment } from "helpers/utils";
import { PRODUCTION, LOCAL_API_URL, PROD_COPIES_URL, LOCAL_COPIES_URL } from "helpers/constants";

const baseUrl =
  window.location.hostname.indexOf("localhost") > -1
    ? LOCAL_API_URL
    : `https://${window.location.hostname}/api`;

const location = getEnvironment();

const baseCopiesUrl = location === PRODUCTION ? PROD_COPIES_URL : LOCAL_COPIES_URL;

export function getCopies(env) {
  return axios.get(`${baseCopiesUrl}?environment=${env}`);
}

export function getCommunitySponsorTickets({ marketingURL, nameKey }) {
  return axios.get(
    `${baseUrl}/public/rateCardItem/communitySponsor/findByMarketingUrlAndOrgNameKey?marketingUrl=${marketingURL}&nameKey=${nameKey}`
  );
}

export function getStaticData() {
  return axios.get(`${baseUrl}/public/staticData/get`);
}

export function applyCouponCode({ couponCode, eventId, eventOrgId }) {
  return axios.get(
    `${baseUrl}/public/getFormTypeByCouponCodeAndEventId?couponCode=${couponCode}&eventId=${eventId}&sponsoringEventOrganizationId=${eventOrgId}`
  );
}

export function getSponsorsData(getSponsorsApi, eventId) {
  return axios.get(`${getSponsorsApi}?eventId=${eventId}`);
}

export function getTicketPurchaseEndTime(eventId) {
  return axios.get(`${baseUrl}/public/event/getSecondsLeftToTicketEnd?eventId=${eventId}`);
}

export function getRegFormsList(getRegistrationFormsApi, registrationId, sponsorOrganizationId) {
  if (sponsorOrganizationId) {
    return axios.get(
      `${getRegistrationFormsApi}?registrationId=${registrationId}&sponsorOrganizationId=${sponsorOrganizationId}`
    );
  }
  return axios.get(`${getRegistrationFormsApi}?registrationId=${registrationId}`);
}

export function getSpeakersList(speakersListApi) {
  return axios.get(speakersListApi);
}
