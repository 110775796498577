import React from "react";
import classNames from "classnames";
import { isIEBrowser } from "helpers/utils";
import { useCopy } from "utility/copyContext";
import WaitListForm from "components/WaitListForm";

export default function RegYetToStart() {
  const { registrationYetToStart, generalStr, header } = useCopy();
  const [openGetTicketForm, setOpenGetTicketForm] = React.useState(false);
  return (
    <section className={classNames("regYetToStart", { "regYetToStart--onIe": isIEBrowser() })}>
      <div className="container">
        <div className="regYetToStart_content">
          <h1 dangerouslySetInnerHTML={{ __html: registrationYetToStart.title }}></h1>

          <p className="joinMailListContent">
            <small title={header.joinWaitList} onClick={() => setOpenGetTicketForm(true)}>
              <img src="https://a.fintechmeetup.com/assets/img/mail_icon.png" alt="" />
            </small>
            <span>
              <small>{registrationYetToStart.joinOur}</small>{" "}
              <span title={header.joinWaitList} onClick={() => setOpenGetTicketForm(true)}>
                {generalStr.mailingList}{" "}
              </span>
              <small dangerouslySetInnerHTML={{ __html: registrationYetToStart.contact }}></small>
            </span>
          </p>
          
        </div>
      </div>
      {openGetTicketForm && <WaitListForm onClose={() => setOpenGetTicketForm(false)} />}
    </section>
  );
}
