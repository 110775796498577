import { UAParser } from "ua-parser-js";
import startsWith from "lodash/startsWith";
import {
  STAGING,
  LOCALHOST,
  PRODUCTION,
  LOCAL_REG_URL,
  SECTION_OFFSET,
  SECTION_OFFSET_ABOUT,
  TEXT_AREA_MAX_LENGTH
} from "./constants";

const DeviceType = {
  TABLET: "tablet",
  MOBILE: "mobile"
};

export function getResolvedString(str, obj) {
  const keys = Object.keys(obj);
  return keys.reduce((convStr, key) => convStr.replace(new RegExp(`{${key}}`, "g"), obj[key]), str);
}

export function getResolvedStringWithComponents({ mainStr, components = {}, objMap = {} }) {
  const replacer = getReplacer(components);
  return getResolvedString(mainStr, objMap).split(" ").map(replacer).filter(Boolean).map(addSpaces);
}

function getReplacer(components) {
  return function replaceStringsWithComponents(str) {
    if (str.match(/#.*#/gi)?.length) {
      return components[str.replace(/(^#)|(#$)/g, "")];
    }
    return str;
  };
}

function addSpaces(str, index) {
  if (typeof str === "string") {
    return `${index === 0 ? "" : " "}${str} `;
  }
  return str;
}

export function scrollBackToSection() {
  let hashValue = window.location.hash.slice(1);
  let section = document.getElementById(hashValue);
  if (section !== null && hashValue !== "") {
    setTimeout(() => {
      section.scrollIntoView(true);
      let scrolledY = window.scrollY;
      if (scrolledY) {
        window.scroll(0, scrolledY - 92);
      }
      window.history.pushState("", "", " ");
    }, 200);
  }
}

export function isIEBrowser() {
  try {
    const parser = new UAParser();
    const browser = parser.getBrowser();
    if (browser.name === "IE") {
      return true;
    }
  } catch (e) {}
  return false;
}

export function getEnvironment() {
  const environment = window.location.hostname;
  if (environment.includes(PRODUCTION) && !environment.includes(STAGING)) {
    return PRODUCTION;
  }
  if (environment.includes(STAGING)) {
    return STAGING;
  } else {
    return LOCALHOST;
  }
}

export function deviceType() {
  const deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
    ? "m"
    : "d";
  return deviceType;
}

export function getDevice() {
  const parser = new UAParser();
  const device = parser.getDevice();
  return device?.type;
}

export function isMobile() {
  const parser = new UAParser();
  const device = parser.getDevice();
  return device?.type === DeviceType.MOBILE;
}

export function isTablet() {
  const parser = new UAParser();
  const device = parser.getDevice();
  return device?.type === DeviceType.TABLET;
}

export function isWindowsOS() {
  const parser = new UAParser();
  return parser.getOS().name?.toLowerCase() === "windows";
}

export function isMacOS() {
  const parser = new UAParser();
  return parser.getOS().name?.toLowerCase() === "mac os";
}

export function homePageCriteoTag() {
  if (getEnvironment() === PRODUCTION) {
    // Criteo Tag when user lands on Home Page
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 81750 },
      { event: "setSiteType", type: deviceType() },
      { event: "viewHome" }
    );
    // Criteo Tag when user lands on Home Page
  } else {
    console.log("Landed on Home Page");
  }
}

export function ticketsPageCriteoEvent() {
  // Criteo Tag when user lands on Tickets Page
  let pathName = window.location.pathname;
  if (getEnvironment() === PRODUCTION && pathName === "/tickets.html") {
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 81750 },
      { event: "setSiteType", type: deviceType() },
      { event: "viewItem", item: "1" },
      { event: "viewBasket", item: [{ id: "1", price: "0", quantity: 1 }] }
    );
    // Criteo Tag when user lands on Tickets Page
  } else if (
    (getEnvironment().includes(LOCALHOST) || getEnvironment().includes(STAGING)) &&
    pathName === "/tickets.html"
  ) {
    console.log("Landed on Tickets Page");
  } else {
    return false;
  }
}

export function sponsorsPageCriteoEvent() {
  // Criteo Tag when user lands on Sponsors Page
  let pathName = window.location.pathname;
  if (getEnvironment() === PRODUCTION && pathName === "/sponsor_form.html") {
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: 81750 },
      { event: "setSiteType", type: deviceType() },
      { event: "viewItem", item: "1" },
      { event: "viewBasket", item: [{ id: "1", price: "0", quantity: 1 }] }
    );
    // Criteo Tag when user lands on Sponsors Page
  } else if (
    (getEnvironment().includes(LOCALHOST) || getEnvironment().includes(STAGING)) &&
    pathName === "/sponsor_form.html"
  ) {
    console.log("Landed on Sponsors Page");
  } else {
    return false;
  }
}

export function productTag() {
  // Criteo Tag when user scrolls to particular or click on Nav Links in header
  window.criteo_q = window.criteo_q || [];
  window.criteo_q.push(
    { event: "setAccount", account: 81750 },
    { event: "setSiteType", type: deviceType() },
    { event: "viewItem", item: "1" }
  );
  // Criteo Tag when user scrolls to particular or click on Nav Links in header
}

export function userScrolledToSection(criteoTagsScroll, setCriteoTagsScroll) {
  let windowScroll = window.pageYOffset;
  let aboutSection = document.getElementById("about");
  let agendaAndSpeakersSection = document.getElementById("agendaSpeakers");
  if (getEnvironment() === PRODUCTION) {
    if (
      aboutSection !== null &&
      windowScroll > aboutSection.offsetTop - SECTION_OFFSET_ABOUT &&
      !criteoTagsScroll.about
    ) {
      setCriteoTagsScroll({ ...criteoTagsScroll, about: true });
      productTag();
      return false;
    }
    if (
      agendaAndSpeakersSection !== null &&
      windowScroll > agendaAndSpeakersSection.offsetTop - SECTION_OFFSET &&
      !criteoTagsScroll.agendaAndSpeakers
    ) {
      setCriteoTagsScroll({ ...criteoTagsScroll, agendaAndSpeakers: true });
      productTag();
      return false;
    }
  } else {
    if (
      aboutSection !== null &&
      windowScroll > aboutSection.offsetTop - SECTION_OFFSET_ABOUT &&
      !criteoTagsScroll.about
    ) {
      setCriteoTagsScroll({ ...criteoTagsScroll, about: true });
      console.log("About section is scrolled");
      return false;
    }
    if (
      agendaAndSpeakersSection !== null &&
      windowScroll > agendaAndSpeakersSection.offsetTop - SECTION_OFFSET &&
      !criteoTagsScroll.agendaAndSpeakers
    ) {
      setCriteoTagsScroll({ ...criteoTagsScroll, agendaAndSpeakers: true });
      console.log("Agenda & Speakers section is scrolled");
      return false;
    }
  }
}

export function generateRegUrl(couponCode, eventOrgID, regURL) {
  let hostName = window.location.hostname;
  if (hostName.includes("www.")) {
    hostName = hostName.replace("www.", "");
  }
  let regurlWithAdditionalParameters = regURL;
  if (eventOrgID && couponCode) {
    regurlWithAdditionalParameters = `${regURL}&spId=${btoa(eventOrgID)}&code=${btoa(couponCode)}`;
  }
  return hostName.indexOf("localhost") > -1
    ? `${LOCAL_REG_URL}${regurlWithAdditionalParameters}`
    : `https://registration.${hostName}${regurlWithAdditionalParameters}`;
}

export function getBase64Decoded(base64Str) {
  if (!base64Str) return null;
  try {
    return atob(base64Str);
  } catch (e) {
    return null;
  }
}

export function logEventInAnalytics() {
  if (getEnvironment() === PRODUCTION || getEnvironment() === STAGING) {
    window.gtag && window.gtag("event", ...arguments);
  }
}

function startsWithProtocol(url) {
  return startsWith(url, "http://") || startsWith(url, "https://");
}

export function getUrlForHref(val) {
  if (startsWithProtocol(val)) {
    return val;
  } else {
    return "http://" + val;
  }
}

export function getFormattedCurrency(amountObj) {
  if (
    !amountObj ||
    amountObj.value === null ||
    amountObj.value === undefined ||
    !amountObj.currencyCode
  ) {
    return "";
  }

  const isIntegerNumber = amountObj.value % 1 === 0;

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: amountObj.currencyCode,
    minimumFractionDigits: isIntegerNumber ? 0 : 2,
    maximumFractionDigits: isIntegerNumber ? 0 : 2
  }).format(amountObj.value);
}

export function setTextAreaMaxLengths(formTextAreaValidationsObj) {
  let textareas = document.getElementsByTagName("textarea");
  for (let i = 0; i < textareas.length; i++) {
    textareas[i].setAttribute(
      "maxlength",
      formTextAreaValidationsObj?.[textareas[i].name]?.maxLength || TEXT_AREA_MAX_LENGTH
    );
    textareas[i].style.resize = "none";
  }
}
