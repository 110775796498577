import React from "react";
import { useCopy } from "utility/copyContext";

export const WhoSection = () => {
  const {
    home: {
      who: {
        title,
        typesOfOrg,
        sectionContent,
        pastEventDetails,
        typesOfSolutions,
        typesOfJobFunctions
      }
    }
  } = useCopy();

  return (
    <section className="global_section global_section_who" id="who">
      <div className="container">
        <h3 className="global_section_title" dangerouslySetInnerHTML={{ __html: title }}></h3>
        <div className="global_section_content">
          <p className="text-center" dangerouslySetInnerHTML={{ __html: sectionContent }}></p>
          <PastEventDetails dataObj={pastEventDetails} />
          <div className="joining_list">
            <ListOfElements dataObj={typesOfOrg} />
            <ListOfElements dataObj={typesOfJobFunctions} />
            <div className="joining_list_wrapper">
              <b>{typesOfSolutions.title}</b>
              <ul className="list-inline">
                {typesOfSolutions.items.map((data, idx) => (
                  <li key={idx}>
                    {data.map((secData, idx) => (
                      <div className="joining_list_wrapper_section" key={idx}>
                        <b>{secData.title}</b>
                        <ul>
                          {secData.items.map((subSecData, idx) => (
                            <li key={idx}>{subSecData}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function ListOfElements({ dataObj }) {
  return (
    <div className="joining_list_wrapper">
      <b>{dataObj.title}</b>
      <ul className="list-inline">
        {dataObj.items.map((data, idx) => (
          <li key={idx}>
            <div className="joining_list_wrapper_section">
              <ul className="list-unstyled">
                {data.map((sectionData, index) => (
                  <li key={index} dangerouslySetInnerHTML={{ __html: sectionData }}></li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function PastEventDetails({ dataObj }) {
  return (
    <div className="past_event_details">
      <b>{dataObj.title}</b>
      <ul className="list-inline">
        {dataObj.items.map((data, idx) => (
          <li key={idx}>
            <img alt="" src={data} />
          </li>
        ))}
      </ul>
    </div>
  );
}
