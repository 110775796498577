import { useCopy } from "utility/copyContext";

export default function Hotel() {
  const { hotel } = useCopy();
  return (
    <main className="hotel_content_wrapper">
      <section className="hotel_content_img">
        <img
          alt="The Venetian Resort"
          src="https://a.fintechmeetup.com/assets/img/hotel-venetian-bg-header.png"
        />
      </section>
      <div className="container">
        <div className="hotel_content">
          <h1 dangerouslySetInnerHTML={{ __html: hotel?.title }} />
          {hotel?.content?.map((txt, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: txt }} />;
          })}
          <a
            target="_blank"
            rel="noreferrer"
            className="global_btn hotel_content_btn"
            href="https://book.passkey.com/gt/219150033?gtid=515ac7b180fe650ebd0b8e2870f90d38"
            title="https://book.passkey.com/gt/219150033?gtid=515ac7b180fe650ebd0b8e2870f90d38">
            {hotel?.bookYourRoom}
          </a>
        </div>
        <div className="hotel_content">
          <h2 dangerouslySetInnerHTML={{ __html: hotel?.flight?.title }} />
          {hotel?.flight?.content?.map((txt, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: txt }} />;
          })}
        </div>
      </div>
    </main>
  );
}
