import React from "react";
import { useCopy } from "utility/copyContext";

export const AgendaSpeakersSection = () => {
  const { home, header } = useCopy();
  return (
    <section
      className="global_section global_section_agendaSpeakers"
      id={header.scrollableSections.agendaSpeakers}>
      <h2
        className="global_section_title"
        dangerouslySetInnerHTML={{ __html: home.agendaSpeakers.title }}
      />
      <div className="container">
        <div className="global_section_description">
          <p dangerouslySetInnerHTML={{ __html: home.agendaSpeakers.description }} />
          <div className="global_section_agendaSpeakers_includes">
            <ul className="list-unstyled">
              {home.agendaSpeakers.agendaSpeakersContent.items.map((item, idx) => {
                return <li key={idx} dangerouslySetInnerHTML={{ __html: item }} />;
              })}
            </ul>
          </div>
          <p dangerouslySetInnerHTML={{ __html: home.agendaSpeakers.downloadAgenda }} />
          <p dangerouslySetInnerHTML={{ __html: home.agendaSpeakers.applyForm }} />
        </div>
      </div>
    </section>
  );
};
