import React from "react";
import { useCopy } from "utility/copyContext";
import { getSpeakersList } from "datasource/api";
import sortBy from "lodash/sortBy";
import { useParams } from "react-router-dom";

export default function useSpeakerService() {
  const { speakers } = useCopy();
  const [isLoading, setIsLoading] = React.useState(false);
  const [speakerData, setSpeakerData] = React.useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = React.useState(null);
  const sortedSpeakersData = sortBy(speakerData, "displayOrder");
  const { speakerId } = useParams();

  React.useEffect(() => {
    if (!speakers?.enableSpeakers || !speakers?.speakersListApi) {
      return;
    }
    setIsLoading(true);
    getSpeakersList(speakers.speakersListApi)
      .then(res => {
        setSpeakerData(
          res.data.map(o => ({
            ...o,
            displayOrder: o.displayOrder ? parseInt(o.displayOrder) : o.displayOrder
          }))
        );
        if (speakerId) {
          setSelectedSpeaker(res.data.find(data => data.speakerRoute === speakerId));
        }
      })
      .catch(err => {
        setSpeakerData(false);
      })
      .finally(res => {
        setIsLoading(false);
      });
  }, [speakerId, speakers?.enableSpeakers, speakers.speakersListApi]);

  return {
    speakers,
    isLoading,
    speakerData,
    setIsLoading,
    setSpeakerData,
    selectedSpeaker,
    setSelectedSpeaker,
    sortedSpeakersData
  };
}
