import React from "react";
import HubspotForm from "react-hubspot-form";
import { getEnvironment } from "helpers/utils";
import { HUBSPOT_PORTAL_ID, PRODUCTION } from "helpers/constants";
import { HubspotFormProductionId, HubspotFormStagingId } from "helpers/enums";

export default function HubSpotForm({
  portalId,
  formName,
  onSubmit = () => {},
  onReady = () => {},
  onLoading = {},
  ...props
}) {
  const location = getEnvironment();
  const formId = React.useMemo(() => {
    if (location === PRODUCTION) {
      return HubspotFormProductionId[formName];
    } else {
      return HubspotFormStagingId[formName];
    }
  }, [formName, location]);

  return (
    <HubspotForm
      formId={formId}
      onReady={onReady}
      onSubmit={onSubmit}
      loading={onLoading}
      portalId={HUBSPOT_PORTAL_ID}
      {...props}
    />
  );
}
