import React from "react";
import { getCopies } from "datasource/api";
import FullPageLoader from "../components/FullPageLoader";
import { LOCALHOST, PROD, STAGING } from "helpers/constants";

const CopyContext = React.createContext();

export function CopyProvider(props) {
  const [messages, setMessages] = React.useState();

  const enviornment = React.useMemo(() => {
    const hostname = window.location.hostname;
    if (hostname.includes(LOCALHOST)) return LOCALHOST;
    if (hostname.includes(STAGING)) return STAGING;
    return PROD;
  }, []);

  React.useEffect(() => {
    // Uncomment to use local copies.json
    // import("./copies.json").then(c => {
    //   setMessages(c.default);
    // });

    getCopies(enviornment).then(res => {
      if (Array.isArray(res.data)) {
        const data = res.data.reduce((acc, el) => {
          return (acc = { ...acc, ...el });
        }, {});
        setMessages({ ...data });
      } else {
        setMessages(res.data);
      }
    });
  }, [enviornment]);

  if (!messages) {
    return <FullPageLoader isLoading={true} />;
  }

  return <CopyContext.Provider value={messages} {...props}></CopyContext.Provider>;
}

export function useCopy() {
  const CopyCtx = React.useContext(CopyContext);

  if (CopyCtx === undefined) {
    throw new Error("This should be used within CopyContext");
  }
  return CopyCtx;
}
