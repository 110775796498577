import React from "react";
import { useCopy } from "utility/copyContext";

export const WelcomeFintech = () =>{
const { home } = useCopy();

return (
        <section className="global_section global_section_welcome" id="welcome">
            <div className="global_section_welcome_img">
                <div className="container">
                    <div className="global_section_welcome_img_top">
                    <picture>
                        <source
                        media="(min-width: 768px)"
                        srcSet={home.coverImg.coverImgDesktop}></source>
                        <source
                        media="(min-width: 320px)"
                        srcSet={home.coverImg.coverImgMobile}></source>
                        <img src={home.coverImg.coverImgDesktop} alt="img" />
                    </picture>
                    </div>
                    <div className="global_section_welcome_img_bottom">
                        <picture>
                            <source
                            media="(min-width: 768px)"
                            srcSet={home.eventAchiveImg.eventAchiveImgDesktop}></source>
                            <source
                            media="(min-width: 320px)"
                            srcSet={home.eventAchiveImg.eventAchiveImgMobile}></source>
                            <img src={home.eventAchiveImg.eventAchiveImgDesktop} alt="img" />
                        </picture>
                    </div>
                </div>
            </div>
            <div className="global_section_loveFintech">
                <div className="container">
                    <h2
                        className="global_section_title"
                        dangerouslySetInnerHTML={{ __html: home.welcomeSection.title }}
                    />
                    <div className="global_section_loveFintech_img">
                        <picture>
                            <source
                            media="(min-width: 768px)"
                            srcSet={home.loveFintechImg.loveFintechImgDesktop}></source>
                            <source
                            media="(min-width: 320px)"
                            srcSet={home.loveFintechImg.loveFintechImgMobile}></source>
                            <img src={home.loveFintechImg.loveFintechImgDesktop} alt="img" />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    )
}