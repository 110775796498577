import React from "react";
import classNames from "classnames";
import { useCopy } from "utility/copyContext";
import { HubSpotFormName } from "helpers/enums";
import HubSpotForm from "components/HubSpotForm";
import FullPageLoader from "components/FullPageLoader";
import { FORM_TEXTAREA_VALIDATIONS } from "./constants";
import { isIEBrowser, setTextAreaMaxLengths } from "helpers/utils";
import WaitListForm from "components/WaitListForm";
import ModalText from "components/ModalText";


export default function ApplyToSpeakForm() {
  window.jQuery = // jugaad for hubspot, as hubspot `onFormSubmitted` requries jquery
    window.jQuery ||
    (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
      serializeArray: () => {}
    }));

  const { applyToSpeakForm, generalStr } = useCopy();
  const { registrationYetToStart, header } = useCopy();
  const [showTopSection, setShowTopSection] = React.useState(true);
  const [openGetTicketForm, setOpenWaitListForm] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState(false);

  return (
    <section className={classNames("applyToSpeak", { "applyToSpeak--onIe": isIEBrowser() })}>
      {showTopSection && (
        <div className="applyToSpeak_topSection">
          <h1 dangerouslySetInnerHTML={{ __html: applyToSpeakForm.title }}></h1>
          {applyToSpeakForm.content && (
            <div className="applyToSpeak_topSection_content">
              <p dangerouslySetInnerHTML={{ __html: applyToSpeakForm.content }}></p>
            </div>
          )}
          <div className="applyToSpeak_topSection_content guidelines">
            <p>Please <span onClick={() => setOpenModalText(true)}> see our guidelines</span> before completing the form to apply to speak at Fintech Meetup.</p>
          </div>
        </div>
        
      )}


      {generalStr.isApplyToSpeakFormVisible && (
        <HubSpotForm
          formName={HubSpotFormName.APPLY_TO_SPEAK}
          onLoading={<FullPageLoader isLoading={true} />}
          onReady={() => setTextAreaMaxLengths(FORM_TEXTAREA_VALIDATIONS)}
          onFormSubmitted={() => setShowTopSection(false)}
        />
      )}

{!generalStr.isApplyToSpeakFormVisible && (
      <p className="joinMailListContent">
      <span>
        <small title={header.joinWaitList} onClick={() => setOpenWaitListForm(true)}>
          <img src="https://a.fintechmeetup.com/assets/img/mail_icon.png" alt="" />
        </small>
        </span>
        <span>
          <small>{registrationYetToStart.joinOur}</small>{" "}
          <span title={header.joinWaitList} onClick={() => setOpenWaitListForm(true)}>
            {generalStr.mailingList}{" "}
          </span>

        </span>
      </p>
      )}
      {openGetTicketForm && <WaitListForm onClose={() => setOpenWaitListForm(false)} />}
      {openModalText && <ModalText onClose={() => setOpenModalText(false)} />}
    </section>
  );
}
