import React from "react";
import { CloseIcon } from "assets/svg";
import { useCopy } from "utility/copyContext";



export default function ModalText(props) {
  const { applyToSpeakForm } = useCopy();

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, []);

  return (
    <div className="waitListFormModal">
      <div className="waitListFormModal_dailog formLoaded">
        <button className="waitListFormModal_close" onClick={props.onClose}>
          <CloseIcon />
        </button>
        <h1 dangerouslySetInnerHTML={{ __html: applyToSpeakForm.popupTitle }}></h1>
        <div dangerouslySetInnerHTML={{ __html: applyToSpeakForm.popupContent }}></div>
      </div>
    </div>
  );
}
