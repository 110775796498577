import React, { useState } from "react";
import FM_LINKS from "routes";
import classNames from "classnames";
import queryString from "query-string";
import { CustomLink } from "components/Link";
import { Link as ScrollLink } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import { useCopy } from "../../utility/copyContext";
import { ReactComponent as Logo } from "assets/img/FN_LOGO.svg";
import { SCROLL_DURATION, SCROLL_OFFSET } from "helpers/constants";
import { EmailIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from "assets/svg";
import Marquee from "react-fast-marquee";
import { isMobile } from "helpers/utils";

export default function Header({ setOpenWaitListForm }) {
  const { generalStr } = useCopy();
  const { header } = useCopy();
  const [showMenu, setShowMenu] = React.useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const headerRef = React.useRef();
  const menuRef = React.useRef();

  React.useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight;
    if (headerHeight) {
      document.body.style.paddingTop = `${headerHeight}px`;
      if (isMobile()) {
        menuRef.current.style.top = `${headerHeight}px`;
        menuRef.current.style.height = `calc(100% - ${headerHeight}px)`;
      }
    }
  }, []);

  return (
    <header className="header smooth" ref={headerRef}>
      <div className="header_top">
        <Marquee loop={0} speed={80} pauseOnHover={true}>
          <p dangerouslySetInnerHTML={{ __html: header.topBar }} />
        </Marquee>
      </div>
      <div className="container">
        <div className="header_container">
          <div className="header_logo">
            <a href="/" title={generalStr.eventName}>
              <Logo />
            </a>
          </div>
          <div className="header_navLinks">
            <HamburgerButton
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              isSubMenuOpen={isSubMenuOpen}
              setIsSubMenuOpen={setIsSubMenuOpen}
            />
            <div className="header_navLinks_top lgVisible">
              <SocialMediaLinks setOpenWaitListForm={setOpenWaitListForm} />
            </div>
            <div
              ref={menuRef}
              className={classNames("header_navLinks_bottom", {
                openNav: showMenu
              })}>
              <NavLinks
                setShowMenu={setShowMenu}
                setIsSubMenuOpen={setIsSubMenuOpen}
                isSubMenuOpen={isSubMenuOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export const HamburgerButton = ({ setShowMenu, showMenu, setIsSubMenuOpen }) => {
  const menuClicked = () => {
    setShowMenu(showMenu => !showMenu);
    if (isMobile()) {
      setIsSubMenuOpen(false);
    }
  };
  return (
    <span
      className={classNames("showMenu xsVisible", { menuOpened: showMenu })}
      onClick={menuClicked}>
      <small className="a smooth"></small>
      <small className="b smooth"></small>
      <small className="c smooth"></small>
    </span>
  );
};

export const SocialMediaLinks = ({ setOpenWaitListForm }) => {
  const { header, generalStr } = useCopy();
  return (
    <ul className="list-inline socialMedia">
      <li>
        <CustomLink
          href="https://www.instagram.com/fintechmeetup"
          target="_blank"
          title={header.instagram}>
          <InstagramIcon title={header.instagram} />
        </CustomLink>
      </li>
      <li>
        <CustomLink href="https://twitter.com/Fintechmeetup" target="_blank" title={header.twitter}>
          <TwitterIcon title={header.twitter} />
        </CustomLink>
      </li>
      <li>
        <CustomLink
          href="https://www.linkedin.com/company/fintechmeetup"
          target="_blank"
          title={header.linkedin}>
          <LinkedinIcon title={header.linkedin} />
        </CustomLink>
      </li>
      <li className="joinMailList">
        <span title={generalStr.mailingList} onClick={() => setOpenWaitListForm(true)}>
          <EmailIcon />
          <span>{generalStr.mailingList}</span>
        </span>
      </li>
    </ul>
  );
};

export function NavLinks({ setShowMenu, isSubMenuOpen, setIsSubMenuOpen }) {
  let navigate = useNavigate();
  const { header } = useCopy();
  const { spId: sponsorOrganizationId } = queryString.parse(window.location.search);

  const toggleSubMenu = React.useCallback(() => {
    if (isMobile()) {
      setIsSubMenuOpen(prevIsSubMenuOpen => !prevIsSubMenuOpen);
    }
  }, [setIsSubMenuOpen]);

  const closeMenu = React.useCallback(() => {
    if (isMobile()) {
      setIsSubMenuOpen(false);
    }
    setShowMenu(false);
  }, [setShowMenu, setIsSubMenuOpen]);

  const redirectToHome = e => {
    let pathName = window.location.pathname;
    let clickedEle = e.target.attributes[1].value;
    closeMenu();
    if (!sponsorOrganizationId && pathName !== "/") {
      navigate({ pathname: FM_LINKS.HOME, hash: "#" + clickedEle });
    } else if (sponsorOrganizationId) {
      window.open(FM_LINKS.HOME + "#" + clickedEle);
      window.history.pushState("", "", "");
    }
  };

  return (
    <React.Fragment>
      <nav className="header_navLinks_bottom_nav text-right">
        <ul className="list-inline">
          <li>
            <ScrollLink
              smooth={true}
              title={header.about}
              offset={SCROLL_OFFSET}
              onClick={redirectToHome}
              duration={SCROLL_DURATION}
              to={header.scrollableSections.about}
              data-id={header.scrollableSections.about}>
              {header.about}
            </ScrollLink>
          </li>
          <li>
            <Link
              target="_blank"
              onClick={closeMenu}
              to={FM_LINKS.FEEDBACK}
              title={header.feedback}>
              {header.feedback}
            </Link>
          </li>
          <li className="hasSubMenu" onClick={toggleSubMenu}>
            <span>{header.content}</span>
            <ul
              className={`list-unstyled header_navLinks_subMenu ${
                isSubMenuOpen ? "subMenuOpen" : ""
              }`}>
              <li>
                <ScrollLink
                  smooth={true}
                  title={header.agenda}
                  offset={SCROLL_OFFSET}
                  onClick={redirectToHome}
                  duration={SCROLL_DURATION}
                  to={header.scrollableSections.agendaSpeakers}
                  data-id={header.scrollableSections.agendaSpeakers}>
                  {header.agenda}
                </ScrollLink>
              </li>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.SPEAKERS}
                  title={header.speakers}>
                  {header.speakers}
                </Link>
              </li>
            </ul>
          </li>
          {/* temporarily commented for Fintech25 */}
          {/* <li>
            <Link
              target="_blank"
              onClick={closeMenu}
              to={FM_LINKS.SCHEDULE}
              title={header.schedule}>
              {header.schedule}
            </Link>
          </li> */}
          <li>
            <Link
              target="_blank"
              onClick={closeMenu}
              to={FM_LINKS.SPONSORS}
              title={header.sponsors}>
              {header.sponsors}
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              onClick={closeMenu}
              to={FM_LINKS.CO_LOCATED_EVENTS}
              title={header.coLocatedEvents}>
              {header.coLocatedEvents}
            </Link>
          </li>
          {/* temporarily commented for Fintech25 */}
          {/* <li>
            <Link target="_blank" onClick={closeMenu} to={FM_LINKS.HOTEL} title={header.hotel}>
              {header.hotel}
            </Link>
          </li> */}

          {!sponsorOrganizationId && (
            <li>
              <Link
                target="_blank"
                onClick={closeMenu}
                to={FM_LINKS.TICKETS}
                title={header.getTicket}>
                {header.getTicket}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </React.Fragment>
  );
}
